import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    mechanicDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const mechanicSlice = createSlice({
    name: "mechanic",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getmechanics
        mechanicsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getmechanics
        categoryFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.categories = entities;
            state.error = null;
        },
        //getmechanicDetails
        mechanicDetailsFetched: (state, action) => {
            const { mechanicDetails } = action.payload;
            state.actionsLoading = false;
            if(mechanicDetails) delete mechanicDetails.password;
            // if(mechanicDetails ) delete mechanicDetails.service_password;
            state.mechanicDetails = mechanicDetails;
            state.error = null;
        },
        // updatemechanic
        mechanicUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.mechanicDetails = action.payload.mechanic;
        },
        mechanicCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        mechanicDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.mechanic_id
            );
        },
    },
});
