import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    faqDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getAllUsers
        userFetched: (state, action) => {
            // const { totalCount, entities } = action.payload;
            const { entities } = action.payload;
            state.listLoading = false;

            if(entities && entities.length > 0){
                state.users = entities.map((val)=>{
                    return {
                        value: val._id,
                        label: val.name
                    }
                })
            }else{
                state.users = entities;
            }
            // state.totalCount = totalCount;
            state.error = null;
        },
        notificationSent: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
    },
});
