import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    sparePartDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const sparePartSlice = createSlice({
    name: "sparePart",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getspareParts
        sparePartsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getspareParts
        officeFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.offices = entities;
            state.error = null;
        },
        //getsparePartDetails
        sparePartDetailsFetched: (state, action) => {
            const { sparePartDetails } = action.payload;
            state.actionsLoading = false;
            if(sparePartDetails) delete sparePartDetails.password;
            // if(sparePartDetails ) delete sparePartDetails.service_password;
            state.sparePartDetails = sparePartDetails;
            state.error = null;
        },
        // updatesparePart
        sparePartUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.sparePartDetails = action.payload.sparePart;
        },
        sparePartCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        sparePartDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.sparePart_id
            );
        },
    },
});
