import React from 'react';
import { NavLink } from 'react-router-dom'


let Footer = () =>{
    return (
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted font-weight-bold mr-2">{new Date().getFullYear()} ©</span>
                    <NavLink to="#" target="_blank" className="text-dark-75 text-hover-primary">
                        FINDMECH
                        {/* | For support call +44-7861348769 */}
                    </NavLink>
                </div>
                <div className="nav nav-dark">
                    {/* <NavLink to="#" target="_blank" className="nav-link pl-0 pr-5">About</NavLink>
                    <NavLink to="#" target="_blank" className="nav-link pl-0 pr-5">Team Contact</NavLink> */}
                </div>
            </div>
        </div>
    )
}

export default Footer;
