import React from "react";
import { useSelector,shallowEqual } from "react-redux";


export function CheckModuleAccess(moduleName) {
    // console.log('moduleName',moduleName)
    const userDetails = useSelector((state) => state.auth.user, shallowEqual);
    let permissions = userDetails?.roles?.roles_permissions

    let userRoles = []
    if(permissions){
        userRoles = permissions.filter(function(value){
            if(value.module_name== moduleName){
                return value
            }
        })
    }

    let access = {
        addAccess:false,
        editAccess:false,
        viewAccess:false,
        deleteAccess:false
    }

    for(let index=0; index < userRoles.length; index++){
        if(userRoles[index] && userRoles[index].permissions){
            let permissions = userRoles[index].permissions.split(',')
            // console.log('permissions',permissions)
            if(permissions.includes('add'))      access.addAccess=true
            if(permissions.includes('edit'))     access.editAccess=true
            if(permissions.includes('view'))     access.viewAccess=true
            if(permissions.includes('delete'))   access.deleteAccess=true
        }
    }

    return access;
}
