//@ts-check
// import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import * as auth from "../app/containers/Auth/_redux/authRedux";
import { userSlice } from "../app/containers/User/_redux/userReducer";
import { superMechanicSlice } from "../app/containers/SuperMechaic/_redux/superMechanicReducer";
import { mechanicSlice } from "../app/containers/mechaic/_redux/mechanicReducer";
import { sparePartSlice } from "../app/containers/sparePart/_redux/sparePartReducer";
import { supplierSlice } from "../app/containers/Supplier/_redux/supplierReducer";
import { categorySlice } from "../app/containers/Category/_redux/categoryReducer";
import { dashboardSlice } from "../app/containers/Dashboard/_redux/dashboardReducer";
import { cmsSlice } from "../app/containers/cms/_redux/cmsReducer";
import { contactSlice } from "../app/containers/contacts/_redux/contactReducer";
import { faqSlice } from "../app/containers/Faq/_redux/faqReducer";
import { notificationSlice } from "../app/containers/notification/_redux/notificationReducer";
import { settingsSlice } from "../app/containers/settings/_redux/settingsReducer";
import { carDealerSlice } from "../app/containers/carDealer/_redux/carDealerReducer";


export const rootReducer = combineReducers({
    toastr: toastrReducer,
    auth: auth.reducer,
    user: userSlice.reducer,
    superMechanic: superMechanicSlice.reducer,
    mechanic: mechanicSlice.reducer,
    sparePart: sparePartSlice.reducer,
    category: categorySlice.reducer,
    suppliers: supplierSlice.reducer,
    dashboard: dashboardSlice.reducer,
    faq: faqSlice.reducer,
    cms: cmsSlice.reducer,
    contact: contactSlice.reducer,
    notification: notificationSlice.reducer,
    settings: settingsSlice.reducer,
    carDealer: carDealerSlice.reducer,
});

/*export function* rootSaga() {
  yield all([auth.saga()]);
} */
