//@ts-check
import React, { Suspense, lazy,useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../layout/_core/SplashScreen";
import {useSelector,shallowEqual,useDispatch,connect,} from 'react-redux';
import { useLocation } from "react-router";
import {getUserProfile} from "./containers/Auth/_redux/authCrud";
import {actions} from "./containers/Auth/_redux/authRedux";


const DashboardPages = lazy(() => import("./containers/Dashboard/index"));
const SuperMechaicPages = lazy(() => import("./containers/SuperMechaic/index"));
const MechaicPages = lazy(() => import("./containers/mechaic/index"));
const SparePartPages = lazy(() => import("./containers/sparePart/index"));
const SupplierPages = lazy(() => import("./containers/Supplier/index"));
const UserPages = lazy(() => import("./containers/User/index"));
const AboutUsPages = lazy(() => import("./containers/cms/index"));
const CategoryPages = lazy(() => import("./containers/Category/index"));
const MyProfile = lazy(() => import("./containers/Profile/index"));
const ContactPages = lazy(() => import("./containers/contacts/index"));
const NotFound = lazy(() => import("./components/404Page"));
const FaqPages = lazy(() => import("./containers/Faq/index"));
const NotificationPages = lazy(() => import("./containers/notification/index"));
const SettingsPages = lazy(() => import("./containers/settings/index"));
const CarDealerPages = lazy(() => import("./containers/carDealer/index"));

function BasePage(props) {
    const user_type = useSelector((state) => state.auth.user.userType, shallowEqual);
    const location = useLocation();

    //using this for getting user data
    // useEffect(()=>{
    //     // if(user_type == 'SUB_CARRIER'){
    //         getUserProfile(user_type).then((resp)=>{
    //             let {data} = resp.data
    //             props.saveData(data);
    //         }).catch((error)=>{
    //             console.log('error',error)
    //         })
    //     // }
    // },[location])

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Route path="/my-profile"     component={MyProfile} />
                {
                    user_type == 'SUPPLIER' && (
                        <>
                            <Redirect exact from="/" to="/spare-part" />
                            <Route path="/spare-part" component={SparePartPages} />
                        </>
                    )
                }

                {
                    user_type == 'SUPERMECHANIC' && (
                        <>
                            <Redirect exact from="/" to="/mechanic" />
                            <Route path="/mechanic" component={MechaicPages} />
                        </>
                    )
                }

                <Redirect exact from="/" to="/dashboard" />
                <Route path="/mechanic" component={MechaicPages} />
                <Route path="/dashboard"      component={DashboardPages} />
                <Route path="/category"       component={CategoryPages} />
                <Route path="/super-mechanic" component={SuperMechaicPages} />
                <Route path="/dealer" component={CarDealerPages} />
                <Route path="/supplier"     component={SupplierPages} />
                <Route path="/user"     component={UserPages} />
                <Route path="/cms"     component={AboutUsPages} />
                <Route path="/contact"     component={ContactPages} />
                <Route path="/faq"     component={FaqPages} />
                <Route path="/notification"     component={NotificationPages} />
                <Route path="/settings"     component={SettingsPages} />
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    );
}



const mapDispatchToProps = (dispatch)=>{
    return{
        saveData : (data)=> dispatch(actions.setUser(data))
    }
}
export default connect(null,mapDispatchToProps)(BasePage)
