import React from "react";
import { NavLink,Switch, Redirect,Route } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../layout/_helpers";
import Login from "./Login";
import Footer from "../../../../layout/footer/footer";
import '../../../../../src/_assets/css/pages/login/login-1.css'

import ForgotPassword from "./ForgotPassword";

export function AuthPage() {
  return (
    <>
        <div className="d-flex flex-column flex-root ">
            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                {/* <div className="login-aside d-flex flex-column flex-row-auto login-bg px-2" style={{"background":"#c8c8c8"}} >
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        <div className="flex-column-auto mt-5 pb-lg-0 pb-10 d-flex align-items-center">
                            <div className=" mr-2">
                                <a className="">
                                    <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl("media/logos/logo1.png")} />
                                </a>
                            </div>
                            <div className="">
                                <h3 className="font-weight-bolder text-dark font-size-h5 font-size-h4-lg">Easymatch2u</h3>
                            </div>
                        </div>
                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10 mb-5 font-size-sm">
                            <div className="text-white">Powered by <b>EASYMATCH2U</b>
                                <span className="text-white ml-2">For help call <b>(973)692-8671</b> or email <b>help@easymatch2u.com</b></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto kt-min-height">
                    <div className="d-flex flex-column-fluid flex-center">
                        <div className="login-form login-signin">
                            <Switch>
                                <Route path="/auth/login" component={Login} />
                                <Route path="/forgot-password" component={ForgotPassword} />
                                <Redirect exact={true} from="/auth" to="/auth/login" />
                                <Redirect to="/auth/login" />
                            </Switch>
                        </div>
                    </div>
                    {/* Footer */}
                    <div className="d-flex justify-content-lg-end justify-content-end align-items-end py-7 py-lg-0">
                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10 mb-5 font-size-sm">
                            <div className="text-black">Powered by <b>FINDMECH</b>
                                {/* <span className="text-black ml-2">For help call <b>(973)692-8671</b> or email <b>help@easymatch2u.com</b></span> */}
                            </div>
                        </div>
                        {/* <NavLink to="#" target="_blank" className="text-dark-75 text-hover-primary">Terms</NavLink> */}
					</div>
                    {/* End Footer */}
                </div>
            </div>
        </div>
    </>
  );
}
