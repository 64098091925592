import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    superMechanicDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const superMechanicSlice = createSlice({
    name: "superMechanic",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getsuperMechanics
        superMechanicsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getsuperMechanics
        officeFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.offices = entities;
            state.error = null;
        },
        //getsuperMechanicDetails
        superMechanicDetailsFetched: (state, action) => {
            const { superMechanicDetails } = action.payload;
            state.actionsLoading = false;
            if(superMechanicDetails) delete superMechanicDetails.password;
            // if(superMechanicDetails ) delete superMechanicDetails.service_password;
            state.superMechanicDetails = superMechanicDetails;
            state.error = null;
        },
        // updatesuperMechanic
        superMechanicUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.superMechanicDetails = action.payload.superMechanic;
        },
        superMechanicCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        superMechanicDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.superMechanic_id
            );
        },
    },
});
