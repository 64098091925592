import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    carDealerDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const carDealerSlice = createSlice({
    name: "carDealer",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getcarDealers
        carDealersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        },
        //getcarDealerDetails
        carDealerDetailsFetched: (state, action) => {
            const { carDealerDetails } = action.payload;
            state.actionsLoading = false;
            if(carDealerDetails) delete carDealerDetails.password;
            // if(carDealerDetails ) delete carDealerDetails.service_password;
            state.carDealerDetails = carDealerDetails;
            state.error = null;
        },
        // updatecarDealer
        carDealerUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.carDealerDetails = action.payload.carDealer;
        },
        carDealerCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        carDealerDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.carDealer_id
            );
        },
    },
});
